<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/users/individuals">
            <button class="btn btn-primary">
              {{ $t('back') }}
              <i class="mdi mdi-keyboard-backspace"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 page-loader-div">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 page-loader-parent">
                <div class="page-loader">
                  <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row content-div d-none2">
      <div class="col-md-3  text-center">
        <div class="card">
          <div class="card-body p-0 pt-4">
            <div class="row">
              <div class="col-md-12">
                <img src="../../../../assets/images/avatar.png" style="width:150px;">
              </div>
              <div class="col-md-12 mt-3">
                <h5>{{ user.first_name }} {{ user.last_name }}</h5>
                <router-link :to="'/chat-support/customers/' + user.id + '?type=user'">
                  <b-button pill variant="outline-primary" class="pl-3 pr-3">
                    إرسال رسالة
                    <i class="mdi mdi-send pl-2"></i>
                  </b-button>
                </router-link>
              </div>
              <div class="col-md-12 mt-3 menu-info">
                <a class="tab-link active" href="#" data-id="#account-info">
                  <i class="dripicons-user"></i>
                  الحساب
                </a>
                <a class="tab-link" href="#" data-id="#business-info">
                  <i class="mdi mdi-office-building"></i>
                  الشركات
                  <small class="float-end">{{ employees.length }}</small>
                </a>
                <a class="tab-link" href="#" data-id="#passengers-info">
                  <i class="mdi mdi-seat-passenger"></i>
                  الركاب
                  <small class="float-end">{{ passengers.length }}</small>
                </a>
                <a class="tab-link" href="#" data-id="#cards-info">
                  <i class="mdi mdi-credit-card-multiple-outline"></i>
                  البطاقات
                  <small class="float-end">0</small>
                </a>
                <a class="tab-link" href="#" data-id="#bookings-info">
                  <i class="mdi mdi-ticket-confirmation-outline"></i>
                  الحجوزات
                  <small class="float-end">{{ bookings.length }}</small>
                </a>
                <a href="javascript:void(0);">
                  <i class="mdi mdi-wallet"></i>
                  المحفظة
                  <small class="float-end">0.00</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="card tab-content" id="account-info">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <h6 class="text-primary">
                  معلومات الحساب
                </h6>
              </div>
              <div class="col-md-4 col-6">
                <p>معرف المستخدم</p>
                <p>رقم الجوال</p>
                <p>الاسم الكامل</p>
                <p>البريد الالكتروني</p>
                <p class="m-0">تاريخ الانضمام</p>
              </div>
              <div class="col-md-8 col-6">
                <p>{{ user.id }}</p>
                <p>{{ user.phone_country_code }}{{ user.phone }}</p>
                <p>{{ user.first_name }} {{ user.last_name }}</p>
                <p>{{ user.email }}</p>
                <p class="m-0">
                  {{ new Date(user.created_at).toLocaleDateString('en-US',
                    { day: '2-digit' }) }} /
                  {{ new
                    Date(user.created_at).toLocaleDateString('en-US', {
                      month: '2-digit'
                    }) }} /
                  {{ new Date(user.created_at).getFullYear() }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="business-info">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <h6 class="text-primary">
                  معلومات الشركات
                </h6>
              </div>
            </div>
            <div v-for="(employee, index) in employees" :key="index" class="row">
              <div class="col-md-4 col-6">
                <p>معرف الشركة</p>
                <p>اسم الشركة</p>
                <p>رقم جوال </p>
                <p class="m-0">البريد الالكتروني</p>
              </div>
              <div class="col-md-8 col-6">
                <p>{{ employee.company.id }}</p>
                <p>{{ employee.company.company_name }}</p>
                <p>{{ employee.company.phone_country_code }}{{ employee.company.phone }}</p>
                <p class="m-0">{{ employee.company.email }}</p>
              </div>
              <div v-if="index != employees.length - 1" class="col-md-12">
                <hr>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="passengers-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-6 col-6">
                <h6 class="text-primary">
                  الركاب
                </h6>
              </div>
              <div class="col-md-6 col-6">
                <div class="float-end d-none d-md-block">
                  <router-link to="/users/individuals/add">
                    <button class="btn btn-primary">
                      إضافة راكب
                      <i class="mdi mdi-plus-thick"></i>
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="col-md-12">
                <md-table v-model="passengers" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="المعرف" md-numeric>
                      {{ item.id }}
                    </md-table-cell>
                    <md-table-cell md-label="الاسم" style="direction: ltr;">
                      {{ item.first_name }} {{ item.last_name }}
                    </md-table-cell>
                    <md-table-cell md-label="النوع">
                      {{ $t(item.type) }}
                    </md-table-cell>
                    <md-table-cell md-label="جواز السفر">
                      <span v-if="passengers_count != 0">
                        {{ item.passport_number }}
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="إنتهاء جواز السفر">
                      <span v-if="passengers_count != 0">
                        {{ item.passport_expiry_date }}
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="البطاقة الشخصية">
                      <span v-if="passengers_count != 0">
                        {{ item.IDNumber }}
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="إنتهاء البطاقة الشخصية">
                      <span v-if="passengers_count != 0">
                        {{ item.id_expiry_date }}
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="الخيارات">
                      <md-menu v-if="passengers_count != 0" md-direction="bottom-start">
                        <i class="mdi mdi-cog-outline font-20" md-menu-trigger></i>
                        <md-menu-content>
                          <!-- <md-menu-item :href="'/users/individuals/details/' + encodeId(item.id)">
                            عرض
                          </md-menu-item>
                          <md-menu-item :href="'/users/individuals/edit/' + encodeId(item.id)">
                            تعديل
                          </md-menu-item>
                          <md-menu-item @click="deleteUser(encodeId(item.id))">
                            <span class="text-danger">حذف</span>
                          </md-menu-item> -->
                        </md-menu-content>
                      </md-menu>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="passengers_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>

            </div>
            <div v-if="passengers_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="cards-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-6 col-6">
                <h6 class="text-primary m-0">
                  بطاقات الائتمان
                </h6>
              </div>
              <div class="col-md-6 col-6">
                <div class="float-end d-none d-md-block">
                  <router-link to="/users/individuals/add">
                    <button class="btn btn-primary">
                      إضافة بطاقة
                      <i class="mdi mdi-plus-thick"></i>
                    </button>
                  </router-link>
                </div>
              </div>
              <div class="col-md-12">
                <md-table v-model="cards" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="الاسم" md-numeric>
                      {{ item.id }}
                    </md-table-cell>
                    <md-table-cell md-label="النوع" style="direction: ltr;">
                      {{ item.phone_country_code }} {{ item.phone }}
                    </md-table-cell>
                    <md-table-cell md-label="رقم البطاقة">
                      {{ item.first_name }} {{ item.last_name }}
                    </md-table-cell>
                    <md-table-cell md-label="الصلاحية">
                      <span v-if="cards_count != 0">
                        0
                      </span>
                    </md-table-cell>
                    <md-table-cell md-label="الخيارات">
                      <md-menu v-if="cards_count != 0" md-direction="bottom-start">
                        <i class="mdi mdi-cog-outline font-20" md-menu-trigger></i>
                        <md-menu-content>
                          <!-- <md-menu-item :href="'/users/individuals/details/' + encodeId(item.id)">
                            عرض
                          </md-menu-item>
                          <md-menu-item :href="'/users/individuals/edit/' + encodeId(item.id)">
                            تعديل
                          </md-menu-item>
                          <md-menu-item @click="deleteUser(encodeId(item.id))">
                            <span class="text-danger">حذف</span>
                          </md-menu-item> -->
                        </md-menu-content>
                      </md-menu>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="cards_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>

            </div>
            <div v-if="cards_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="bookings-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-6 col-6">
                <h6 class="text-primary">
                  الحجوزات
                </h6>
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-12">
                <md-table v-model="bookings" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="رقم الحجز" md-numeric>
                      {{ item.booking_reference }}
                    </md-table-cell>
                    <md-table-cell md-label="المسار" style="direction: ltr;">
                      {{ item.from }}-{{ item.to }}
                    </md-table-cell>
                    <md-table-cell md-label="الذهاب">
                      {{ item.departure_date }}
                    </md-table-cell>
                    <md-table-cell md-label="العودة">
                      {{ item.return_date }}
                    </md-table-cell>
                    <md-table-cell md-label="الحالة">
                      <span v-if="bookings_count != 0" v-bind:class="item.status"> {{
                        $t(item.status) }}</span>
                    </md-table-cell>
                    <md-table-cell md-label="الخيارات">
                      <md-menu v-if="bookings_count != 0" md-direction="bottom-start">
                        <i class="mdi mdi-cog-outline font-20" md-menu-trigger></i>
                        <md-menu-content>
                          <!-- <md-menu-item :href="'/users/individuals/details/' + encodeId(item.id)">
                            عرض
                          </md-menu-item>
                          <md-menu-item :href="'/users/individuals/edit/' + encodeId(item.id)">
                            تعديل
                          </md-menu-item>
                          <md-menu-item @click="deleteUser(encodeId(item.id))">
                            <span class="text-danger">حذف</span>
                          </md-menu-item> -->
                        </md-menu-content>
                      </md-menu>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="bookings_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>

            </div>
            <div v-if="bookings_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import $ from 'jquery';
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
export default {
  page: {
    title: "user details",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "user details",
      passengers_count: 0,
      cards_count: 0,
      bookings_count: 0,
      cards: [{}, {}, {}, {}, {}, {}, {}],
      bookings: [{}, {}, {}, {}, {}, {}, {}],
      passengers: [{}, {}, {}, {}, {}, {}],
      currentPage: 1,
      user: [],
      employees: [],
      rows: null,
      perPage: 10,
    };
  },
  mounted() {
    this.jquery();
    $('.page-loader-div').show();
    var url = `${this.$appUrl}/api/admin/users/show/${this.$route.params.id}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.user = response.data.user;
      this.employees = response.data.user.employees;
      this.passengers_count = response.data.user.passengers.length;
      this.bookings_count = response.data.user.bookings.length;
      if (this.passengers_count > 0) {
        this.passengers = response.data.user.passengers;
      }
      if (this.bookings_count > 0) {
        this.bookings = response.data.user.bookings;
      }
      $('.content-div').removeClass('d-none2');
      $('.page-loader-div').hide();
    });
  },
  methods: {
    handlePagination() {
      var url = `${this.$appUrl}/api/admin/users?page=${this.currentPage}&perPage=${this.perPage}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        this.tableData = response.data.users.data;
        this.rows = response.data.users.total;
      });
    },
    jquery() {
      $(document).on('click', '.menu-info .tab-link', function () {
        var id = $(this).data("id");
        $(document).find('.menu-info .tab-link').removeClass('active');
        $(this).addClass('active');
        $(document).find('.tab-content').hide();
        $(id).show();
      });
    }
  }
};
</script>
<style lang="scss">
.md-table.md-theme-default .md-table-row td {
  text-align: center;
}

.md-table.md-theme-default .md-table-head {
  text-align: center;
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.table-responsive:has(.page-loader-parent) {
  min-height: 350px;
}

.md-icon.md-theme-default.md-icon-image svg {
  display: none;
}

.card {
  .md-field>.md-icon:after {
    background-color: rgb(0 0 0 / 0%);
  }
}

.menu-info {
  text-align: initial;

  a {
    color: #000 !important;
    width: 100%;
    display: block;
    border-top: 1px solid #ededed;
    padding: 15px 20px;
    font-size: 16px;

    &.active {
      background-color: #f7f7f7;
    }

    i {
      display: inline-block;
      min-width: 1.75rem;
      padding-bottom: 0.125em;
      line-height: 1.40625rem;
      vertical-align: middle;
      color: #00a99d;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      font-size: 18px;
    }
  }
}
</style>